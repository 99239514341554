import React from "react"
import Turnstile from "react-turnstile"
import { GATSBY_CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY } from "gatsby-env-variables"

const Captcha = ({ setIsCaptchaNotSolved }) => {
  return (
    <div className="columns is-centered">
      <Turnstile
        sitekey={GATSBY_CLOUDFLARE_TURNSTILE_CAPTCHA_SITE_KEY}
        onSuccess={setIsCaptchaNotSolved(false)}
      />
    </div>
  )
}

export default Captcha
