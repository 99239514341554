import React, { useContext, useEffect, Fragment, useReducer } from "react"
import { Formik, Form } from "formik"

import Loading from "elements/Loading"
import Message from "elements/Message"
import MedicineInfo from "./MedicineInfo"
import TotalPriceInfo from "./TotalPriceInfo"
import SCPWDDisclaimer from "./SCPWDDisclaimer"
import AddMedicineHelper from "./AddMedicineHelper"
import AssistanceCalculator from "./AssistanceCalculator"
import MedicineQuantityInput from "./MedicineQuantityInput"
import SelectCustomerDiscountType from "./SelectCustomerDiscountType"

import {
  changeQuantity,
  getMedicineForm,
  generateTotalMedsToPay,
} from "../services/cart"
import { hasSCPWDDiscount, calculateDiscountValue } from "../services/discount"
import { AppContext } from "../../../context/AppContext"
import { useDiscountCoupons } from "../hooks/useDiscountCoupons"
import {
  getCustomerTypeOptions,
  hasTierDiscount,
} from "../services/customerTypes"
import useAssistanceCalculatorImages from "../services/hooks/useAssistanceCalculatorImages"
import {
  AssistanceCalculatorReducer,
  initialState,
} from "../services/reducers/AssistanceCalculatorReducer"

const AddMedicineModal = ({ addToCart, medicine, isSpecialOrder }) => {
  const data = useAssistanceCalculatorImages()
  const discountCoupons = useDiscountCoupons()
  const { dispatch } = useContext(AppContext)
  const customerTypeOptions = getCustomerTypeOptions({ medicine })
  const [calculatorState, calculatorDispatch] = useReducer(
    AssistanceCalculatorReducer,
    {
      ...initialState({ customerTypeOptions }),
    }
  )
  const {
    medicineQuantity,
    maxQuantity,
    discountType,
    customerType,
    discountTier,
    discountQueue,
    discountValues,
    isLoading,
  } = calculatorState
  const medicineForm = getMedicineForm({ medicine })
  const hasSCPWDDiscountInCoupon = hasSCPWDDiscount({
    discountCoupons: discountCoupons?.allDiscountCoupons?.nodes,
    medicine,
  })
  const hasTierDiscountInCoupon = hasTierDiscount({
    discountCoupons: discountCoupons?.allDiscountCoupons?.nodes,
    medicine,
  })

  useEffect(() => {
    if (
      parseInt(medicineQuantity) > 0 ||
      !!customerType ||
      !!discountType ||
      !!discountTier
    ) {
      calculatorDispatch({ type: "SET_IS_LOADING", payload: true })
      if (discountQueue) clearTimeout(discountQueue)
      calculatorDispatch({
        type: "SET_DISCOUNT_QUEUE",
        payload: setTimeout(
          () =>
            calculateDiscountValue({
              medicine,
              calculatorState,
              calculatorDispatch,
              isSpecialOrder,
            }),
          1000
        ),
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [medicineQuantity, customerType, discountType, discountTier])

  return (
    <Formik
      initialValues={{
        qty: medicineQuantity,
        customerType,
        discountType,
        discountTier,
      }}
      onSubmit={(values) => {
        dispatch({ type: "HIDE_MODAL" })
        if (addToCart)
          addToCart(
            { ...medicine, customerType, discountType, discountTier },
            values.qty,
            discountValues
          )
      }}
    >
      {({ values, setFieldValue }) => {
        const hasDailyUseLimit = discountValues?.filter(
          (discountValue) => discountValue?.dailyUseLimit
        )
        if (hasDailyUseLimit.length > 0)
          values.qty = hasDailyUseLimit[0].dailyUseLimit

        return (
          <Form>
            {isLoading ? (
              <div className="h-full">
                <Loading />
              </div>
            ) : (
              <div className="mx-3 mx-1-mobile">
                <MedicineInfo medicine={medicine} medicineForm={medicineForm} />
                <SelectCustomerDiscountType
                  hasSCPWDDiscountInCoupon={hasSCPWDDiscountInCoupon}
                  hasTierDiscountInCoupon={hasTierDiscountInCoupon}
                  values={values}
                  isSpecialOrder={isSpecialOrder}
                  medicine={medicine}
                  calculatorDispatch={calculatorDispatch}
                />
                {medicine?.brand == "Tremfya" && (
                  <Message color="warning">
                    Patients enrolled before August 19, 2024 will be eligible
                    for the maintenance PAP until December 31, 2024. Patients
                    enrolled after August 19, 2024 will only be eligible for the
                    initiation PAP.
                  </Message>
                )}
                <MedicineQuantityInput
                  changeQuantity={changeQuantity}
                  setFieldValue={setFieldValue}
                  values={values}
                  calculatorDispatch={calculatorDispatch}
                  calculatorState={calculatorState}
                />
                <AddMedicineHelper
                  image={data?.medicines?.childImageSharp?.fixed}
                  calculatorState={calculatorState}
                  hasSCPWDDiscount={hasSCPWDDiscountInCoupon}
                />
                {medicineQuantity > 0 && (
                  <AssistanceCalculator
                    medicine={medicine}
                    quantity={medicineQuantity}
                    discountValues={discountValues}
                    isLoading={isLoading}
                  />
                )}
                {medicineQuantity > 0 && !isLoading && (
                  <Fragment>
                    <SCPWDDisclaimer
                      hasSCPWDDiscount={hasSCPWDDiscountInCoupon}
                      discountType={discountType}
                    />
                    <TotalPriceInfo
                      medicine={medicine}
                      qty={generateTotalMedsToPay({
                        medicine,
                        quantity: medicineQuantity,
                      })}
                      discountValues={discountValues}
                    />
                  </Fragment>
                )}

                <button
                  type="submit"
                  className="button is-fullwidth is-primary my-2"
                  disabled={
                    medicineQuantity < 1 ||
                    medicineQuantity > maxQuantity ||
                    isLoading
                  }
                >
                  Add to cart
                </button>
              </div>
            )}
          </Form>
        )
      }}
    </Formik>
  )
}

export default AddMedicineModal
